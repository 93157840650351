//
//
//
//
//
//
//
//
//
//
//
//

import componentMinxins from '../ComponentMinxins'

export default {
  mixins: [componentMinxins],
  name: "NumberInput",
  components: {},
  props:{
    value:{
      type: Number,
      default: null
    },
    placeholder:{
      type: String,
      default: '请输入数值'
    },
    readerMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {}
  },
  methods: {}
}
